import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
	colors: {
		purple: {
			700: '#1f2733',
			300: '#7500c0',
			800: '#7500c0',
			100: '#7500c0',
			200: '#7500c0'
		}
	},
	styles: {
		global: (props) => ({
			body: {
				bg: mode('purple.50', 'purple.800')(props),
				fontFamily: "'Roboto', sans-serif"
			},
			html: {
				fontFamily: "'Roboto', sans-serif"
			}
		})
	}
};
