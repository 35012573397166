import axios from "axios";
import { headers } from "../../helper/api";
import * as authTypes from "./authTypes";
// import config from "../../config";

export const getUserDetails = (idToken) => (dispatch, getState) => {
  const activeAccount = getState().auth.activeAccount;
  let getUserDetailsUrl = "https://mycloudapi.mywizard360.com/auth/token";  
  return axios
    .get(getUserDetailsUrl, {
      headers: {
        ...headers,
        Authorization: idToken,
      },
      params: { inititive: activeAccount },
    })
    .then((res) => {
      localStorage.setItem("userDetails", res )
      res.data.profile = res.data.profile === undefined ? "" : res.data.profile;
      var copyData = res.data.profile;

      let array;
      if (copyData[0] === "[") {
        array = res.data.profile
          .slice(1, -1)
          .replace(" ", "")
          .split(",");
      } else {
        array = res.data.profile.replace(" ", "").split(",");
      }

      for (var i = 0; i < array.length; i++) {
        var x = array[i].replace(/['"/@/&/#//]+/g, "").trim();
        array[i] = x;
      }
      res.data.profile = array;

      let { exp } = res.data;
      let expirationDate_number = parseInt(exp, 10);
      const currentTime = new Date().getTime() / 1000;
      let currenTime_number = parseInt(currentTime, 10);

      let isAuthenticated = false;

      if (currenTime_number < expirationDate_number) {
        isAuthenticated = true;
      } else if (currenTime_number > expirationDate_number) {
        // window.location.href = "https://mycloudconsole.auth.ap-southeast-2.amazoncognito.com/login?client_id=dthv8qps0htk0mm89gfv2rs6t&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile+provisioning/*&redirect_uri=https://training.mywizard-aiops.com/auth";
        window.location.href = "https://mycloudconsole.auth.ap-southeast-2.amazoncognito.com/login?client_id=onfv5bg5mh1l71v65brq7ftpb&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile+provisioning/*&redirect_uri=https://training.mywizard-aiops.accenture.com/auth"
        localStorage.clear();
        return false;
      } else {
        // window.location.href = "https://mycloudconsole.auth.ap-southeast-2.amazoncognito.com/login?client_id=dthv8qps0htk0mm89gfv2rs6t&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile+provisioning/*&redirect_uri=https://training.mywizard-aiops.com/auth";
        window.location.href = "https://mycloudconsole.auth.ap-southeast-2.amazoncognito.com/login?client_id=onfv5bg5mh1l71v65brq7ftpb&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile+provisioning/*&redirect_uri=https://training.mywizard-aiops.accenture.com/auth"
        localStorage.clear();
        return false;
      }
      
      dispatch({
        type: authTypes.inititive,
        data: res.data.profile,
      });

      dispatch({
        type: authTypes.SAVE_USER_DETAILS,
        data: res.data,
        isAuthenticated,
      });
      
    })
    .catch((err) => {
        // window.location.href = "https://mycloudconsole.auth.ap-southeast-2.amazoncognito.com/login?client_id=dthv8qps0htk0mm89gfv2rs6t&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile+provisioning/*&redirect_uri=https://training.mywizard-aiops.com/auth";
        window.location.href = "https://mycloudconsole.auth.ap-southeast-2.amazoncognito.com/login?client_id=onfv5bg5mh1l71v65brq7ftpb&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile+provisioning/*&redirect_uri=https://training.mywizard-aiops.accenture.com/auth"
        localStorage.clear();
        console.log(err);  
    });
};

export const signOut = () => ({
  type: authTypes.SIGNIN_SUCCESS,
});

export const saveTokens = (tokenObj) => ({
  type: authTypes.SAVE_TOKENS,
  data: tokenObj,
});

// export const getAuthorizationPermissions = (email, adRole, idToken) => (
//   dispatch,
//   getState
// ) => {
//   //const idToken = localStorage.getItem("token");
//   const activeAccount = getState().auth.activeAccount;
//   const eId = email === undefined ? undefined : email.split("@")[0];
//   const newAdRole = Array.isArray(adRole) ? adRole : [adRole];
//   return axios
//     .post(
//       config.urls.apis.rbac +
//         `?customer_enterprise_id=${eId}&inititive=${activeAccount}`,
//         newAdRole,
//       {
//         headers: { ...headers, Authorization: `Bearer ${idToken}` },
//       }
//     )
//     .then((res) => {
//       localStorage.setItem(
//         "userRoleBasedAccessControl",
//         JSON.stringify({ data: res.data })
//       );
//       dispatch({
//         type: authTypes.CAM_PERMISSIONS,
//         data: res.data,
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
